<template>
  <div v-loading="loading">
    <div v-if="!loading">
      <div class v-if="isCurrentSlugPresent">
        <div v-if="getCurrentStep">
          <template>
            <el-row>
              <el-col :span="24">
                <div v-if="getCurrentStep.type == 'ENTITY'">
                  <InteractiveEntityExecute v-if="getCurrentStep.type == 'ENTITY'" :step="getCurrentStep"
                    :templateData="getCurrentStep.template" :existedEntityDataId="getCurrentEntityExistedData(getCurrentStep)
                      " :workflowDetailsId="formbuilderDataId" :is-execute="isEdit" :isFormFormbuilder="true"
                    :currentStepIndex="getCurrentStepIndex" :fromSenderSide="true"
                    :isNotSentformbuilder="notSentFormbuilder" @moveNextStep="moveNextStep" @movePrevStep="movePrevStep"
                    @updateAndNext="updateAndNext" @submitEntityData="updateAndNext">
                  </InteractiveEntityExecute>
                </div>
                <PreviewTemplate v-else :templateData="getCurrentStep.template" :workflow-details-id="getFormBuilder._id"
                  :formbuilderDataId="formbuilderDataId" :templateDataId="getCurrentStep.templateDataId"
                  :is-execute="isEdit" :templateDataIds="this.getUserFormbuilderData.template_data_ids
                    " :fromFormbuilder="true" :has-next="getCurrentStep.has_next" :isEdit="isEdit"
                  :formbuilderStepId="getCurrentStep._id" :isNotSentformbuilder="notSentFormbuilder"
                  :currentStep="getCurrentStep" :currentStepIndex="getCurrentStepIndex" />
              </el-col>
              <!-- <el-dialog
                top="0"
                :fullscreen="true"
                :visible.sync="showRepeatingTemplatesData"
              >
                <RepeatableTemplatesData
                  v-if="showRepeatingTemplatesData"
                  :templateData="getCurrentStep.template"
                  :workflow-data-id="entityDataId"
                ></RepeatableTemplatesData>
              </el-dialog> -->
            </el-row>
          </template>
        </div>
      </div>

      <div v-else style="text-align: center; color: red">
        <h3>Invalid step</h3>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewTemplate from "./../templates/PreviewTemplate";
import { mapGetters } from "vuex";
import { bus } from "../../main";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";

export default {
  components: {
    PreviewTemplate,
    InteractiveEntityExecute: () =>
      import("../entity/interactiveEntityExecute.vue"),
  },
  mixins: [FormbuilderHelper],
  data() {
    return {
      currentSlug: null,
      loading: false,
      formbuilderDataId: null,
      showRepeatingTemplatesData: false,
    };
  },
  mounted() {
    this.getNecessaryInfo();
    bus.$on("repeatable-template-added", () => {
      // this.showRepeatingTemplatesData = true;
    });
  },
  computed: {
    ...mapGetters("formBuilders", ["getFormBuilder", "getFormBuilderPrevRouter"]),
    ...mapGetters("formbuilderData", ["getUserFormbuilderData"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    getCurrentStepIndex() {
      return this.getAllSteps.findIndex(
        (e) => e.slug == this.$route.params.template_code
      );
    },
    getAllSteps() {
      if (this.getFormBuilder && this.getFormBuilder.steps) {
        return this.getFormBuilder.steps;
      }
      return [];
    },
    getCurrentEntityExistedData() {
      return (step) => {
        if (
          this.getFormBuilder?.form_builders_owner == "ENTITY" &&
          step?.settings?.isLoginEntity &&
          this.getAuthenticatedUser?.account_data_id
        ) {
          return this.getAuthenticatedUser.account_data_id;
        } else {
          const formbuilderTemplateData = this.getFormBuilder?.steps.find(
            (e) => e.entity_id == step.entity._id || e.entity_id == step.entity
          );
          const currenctTemplateData = (
            this.getUserFormbuilderData?.formbuilder_steps_data || []
          ).find(
            (e) => e.entity_id == step.entity._id || e.entity_id == step.entity
          );
          return (
            currenctTemplateData?.entity_data_id ||
            currenctTemplateData?.entityDataId ||
            formbuilderTemplateData?.entity_data_id ||
            formbuilderTemplateData?.entityDataId
          );
        }
      };
    },
    getStepUrl() {
      return (step) => {
        let url = "";
        if (this.isApplicationUser) {
          url = "/ap";
        }
        url = url + "/fb/";
        if (this.$route.params?.formbuilder_data_id) {
          url =
            url +
            `${this.isEdit ? "edit" : "view"}/${this.$route.params.formbuilder_code
            }/${step.slug}/${this.$route.params.formbuilder_data_id}`;
        } else {
          url =
            url +
            `${this.isEdit ? "edit" : "view"}/${this.$route.params.formbuilder_code
            }/${step.slug}`;
        }
        if (step.type == "ENTITY") {
          let index = this.$router?.query?.temp_id;
          if (!index && index != 0 && index != "0") {
            index = 0;
          }
          url = url + `?step_id=${step._id}&temp_id=${index}`;
          if (this.$route.query?.origin) {
            url = url + "&origin=" + this.$route.query?.origin;
          }
        } else {
          if (this.$route.query?.origin) {
            url = url + "?origin=" + this.$route.query?.origin;
          }
        }
        return url;
      };
    },
    isApplicationUser() {
      if (
        [
          "ApplicationUserFormbuilderView",
          "ApplicationUserFormbuilderViewStep",
          "ApplicationUserFormbuilderEdit",
          "ApplicationUserFormbuilderEditStep",
        ].indexOf(this.$route.name) !== -1
      ) {
        return true;
      }
      return false;
    },
    slugs() {
      if (this.getFormBuilder && this.getFormBuilder.steps) {
        return this.getFormBuilder.steps.map((step) => step.slug);
      }
      return [];
    },
    isCurrentSlugPresent() {
      if (this.slugs && this.slugs.length) {
        if (this.slugs.indexOf(this.currentSlug) === -1) {
          return false;
        }
        return true;
      }
      return true;
    },

    getTemplateDataIdMap() {
      if (this.formbuilderDataId && this.getUserFormbuilderData) {
        let templateDataIdMap = {};

        if (
          this.getUserFormbuilderData.formbuilder_steps_data &&
          this.getUserFormbuilderData.formbuilder_steps_data.length
        ) {
          this.getUserFormbuilderData.formbuilder_steps_data.forEach((e) => {
            templateDataIdMap[e.template_id] =
              e.created_contacts && e.created_contacts.length
                ? e.created_contacts
                : e.template_data_id;
          });
        }
        return templateDataIdMap;
      } else {
        return {};
      }
    },
    getEntityDataIdMap() {
      if (this.formbuilderDataId && this.getUserFormbuilderData) {
        let entityDataIdMap = {};

        if (
          this.getUserFormbuilderData.entity_data_ids &&
          this.getUserFormbuilderData.entity_data_ids.length
        ) {
          this.getUserFormbuilderData.entity_data_ids.forEach((e) => {
            entityDataIdMap[e.entity_id] = e.entity_data_id;
          });
        }
        return entityDataIdMap;
      } else {
        return {};
      }
    },
    getAssetDataIdMap() {
      if (this.formbuilderDataId && this.getUserFormbuilderData) {
        let assetDataIdMap = {};
        if (
          this.getUserFormbuilderData.assets_data &&
          this.getUserFormbuilderData.assets_data.length
        ) {
          this.getUserFormbuilderData.assets_data.forEach((e) => {
            assetDataIdMap[e.asset] = e.asset_data_id;
          });
        }
        return assetDataIdMap;
      } else {
        return {};
      }
    },

    getStepsObject() {
      if (this.getFormBuilder && this.getFormBuilder.steps) {
        const data = {};
        this.getFormBuilder.steps.forEach((step, index, arr) => {
          // it will use to determine finish step
          step.has_next = index < arr.length - 1;

          if (step.type === "TEMPLATE") {
            step.templateDataId = this.getTemplateDataIdMap[step.template._id];
          } else if (
            step.type === "ENTITY" &&
            this.getEntityDataIdMap[step.entity._id]
          ) {
            step.entityDataId = this.getEntityDataIdMap[step.entity._id];
          } else {
            step.assetDataId = this.getAssetDataIdMap[step.type];
          }

          data[step.slug] = step;
        });

        return data;
      }
      return {};
    },

    getCurrentStep() {
      if (this.getStepsObject && this.currentSlug) {
        const step = this.getStepsObject[this.currentSlug];
        if (step && step.type !== "TEMPLATE") {
          return step;
        }
        return step;
      }

      return null;
    },
    getDefautComponent() {
      return (step) => {
        let sections = {
          COMPANY_DOCUMENTS: "company-documents-section",
          OFFER_LETTER: "offer-letter-section-view",
          COMPANY_BENEFIT: "company-benefits-section",
        };
        return sections[step.type];
      };
    },
    isEdit() {
      return (
        this.$route.name == "FormbuilderEditStep" ||
        this.$route.name == "ApplicationUserFormbuilderEditStep"
      );
    },
    notSentFormbuilder() {
      return (
        this.getUserFormbuilderData &&
        this.getUserFormbuilderData.formbuilder_data_status != "COMPLETED" &&
        !this.getUserFormbuilderData.sent_at
      );
    },
  },
  methods: {
    updateAndNext(data) {
      console.log("data", data, this.getFormBuilderPrevRouter);
      if (this.getFormBuilderPrevRouter) {
        location.href = this.getFormBuilderPrevRouter;
        this.$store.commit(
          "formBuilders/setFormBuilderPrevRouter",
          null,
          {
            root: true,
          }
        );
      }
    },
    moveNextStep() {
      let index = this.$route.query?.temp_id;
      if (index == -1) {
        index = 0;
      }
      let url = this.getStepUrl(this.getCurrentStep);
      url = url.replace("temp_id=0", `temp_id=${parseInt(index) + 1}`);
      this.$router.push({ path: url });
    },
    movePrevStep() {
      let index = this.$route.query?.temp_id;
      if (index == -1) {
        index = 0;
      }
      let url = this.getStepUrl(this.getCurrentStep);
      url = url.replace("temp_id=0", `temp_id=${parseInt(index) - 1}`);
      this.$router.push({ path: url });
    },
    async getNecessaryInfo() {
      this.currentSlug = this.$route.params.template_code;
      this.formbuilderDataId = this.$route.params.formbuilder_data_id;

      if (
        !this.getFormBuilder ||
        Object.keys(this.getFormBuilder).length === 0
      ) {
        this.loading = true;

        const workflowCode = this.$route.params.formbuilder_code;
        this.step = this.$route.query.step || 1;

        await this.$store.dispatch("formBuilders/fetchFormBuilderByCode", {
          code: workflowCode,
          include_template_details: true,
          include_entity_details: true,
          include_documents_details: true,
        });
        this.loading = false;
      }

      if (this.formbuilderDataId) {
        this.loading = true;
        let params = {
          id: this.formbuilderDataId,
          include_contacts_data: true,
        };
        await this.$store.dispatch(
          "formbuilderData/fetchUserFormbuilderData",
          params
        );
        if (this.getUserFormbuilderData) {
          let formbuilderData = { ...this.getFormBuilder };
          let steps = this.getUserFormbuilderData.formbuilder_steps_data;
          if (formbuilderData?.steps) {
            formbuilderData.steps = formbuilderData.steps.map((el) => {
              if (el.type == "ENTITY") {
                let currentStep = steps.find(
                  (e) =>
                    e.entity_id == el?.entity?._id || e.entity_id == el.entity
                );
                el.entityDataId = currentStep?.entity_data_id
                  ? currentStep.entity_data_id
                  : "";
              }
              return el;
            });
          }
          this.$store.commit(
            "formBuilders/setFormBuilder",
            {},
            {
              root: true,
            }
          );
          this.$store.commit("formBuilders/setFormBuilder", formbuilderData, {
            root: true,
          });
        }
        this.loading = false;
      }
    },
    async goToNext(step) {
      console.log({ step });
    },
    getAllTemplatesData() {
      console.log("GET USER WFD : ", this.getUserFormbuilderData);
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
  },
};
</script>

<style lang="scss">
.template-body-section {
  .form-builder {
    .form-fields-holder {
      @media (max-width: 991.98px) {
        &>div {
          position: relative !important;
          left: 10px !important;
          top: 0px !important;
          margin-top: 30px;
          width: calc(100% - 20px) !important;

          input {
            width: 100%;
          }

          .heading {
            margin-top: 40px !important;
          }

          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
